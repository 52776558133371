import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3fbab063"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "section-header big" }
const _hoisted_2 = { class: "email-pending-action" }
const _hoisted_3 = {
  key: 1,
  class: "thin-divider mt-1"
}
const _hoisted_4 = { class: "small-label mt-3" }
const _hoisted_5 = { class: "group-permission" }
const _hoisted_6 = { class: "group-label mr-1" }
const _hoisted_7 = { class: "group-checkbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadScrum = _resolveComponent("BreadScrum")!
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ListItemEditInfo = _resolveComponent("ListItemEditInfo")!
  const _component_PermissionCheckBox = _resolveComponent("PermissionCheckBox")!
  const _component_AfterLoginPage = _resolveComponent("AfterLoginPage")!
  const _component_UpdateTextPopup = _resolveComponent("UpdateTextPopup")!
  const _component_UpdateNamePopup = _resolveComponent("UpdateNamePopup")!
  const _component_InfoSendVerificationEmail = _resolveComponent("InfoSendVerificationEmail")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AfterLoginPage, null, {
      "top-part": _withCtx(() => [
        _createVNode(_component_BreadScrum, { paths: _ctx.databreadScrumLinks }, null, 8, ["paths"])
      ]),
      "left-bottom-part": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("member.titleProfileSetting")), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userInfoToShow, (value, name) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: name }, [
            value
              ? (_openBlock(), _createBlock(_component_ListItemEditInfo, {
                  key: 0,
                  "has-divider": name === 'username',
                  "info-title": _ctx.getRowLabel(name),
                  "info-value": value,
                  onOnEdit: ($event: any) => (_ctx.showEditPopup(name)),
                  class: _normalizeClass({ 'email-pending': name == 'emailPending' }),
                  "disable-action": name == 'email' && _ctx.userInfoToShow.emailPending != ''
                }, _createSlots({ _: 2 }, [
                  (name == 'emailPending')
                    ? {
                        name: "tail",
                        fn: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_TextButton, {
                              icon: require('icons/re_invite.svg'),
                              text: _ctx.$t('adminManagement.resendConfirmEmail'),
                              onClick: _ctx.handleResend
                            }, null, 8, ["icon", "text", "onClick"]),
                            _createVNode(_component_TextButton, {
                              icon: require('icons/stop.svg'),
                              text: _ctx.$t('common.undoChanges'),
                              class: "text-danger mt-2",
                              onClick: _ctx.handleCancel
                            }, null, 8, ["icon", "text", "onClick"])
                          ])
                        ])
                      }
                    : undefined
                ]), 1032, ["has-divider", "info-title", "info-value", "onOnEdit", "class", "disable-action"]))
              : _createCommentVNode("", true),
            (name == 'emailPending' && value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("adminManagement.permission")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("adminManagement.permission")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("adminManagement.browsing")), 1),
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("adminManagement.update")), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lstPermission, (permission, i) => {
              return (_openBlock(), _createBlock(_component_PermissionCheckBox, {
                key: i,
                modelValue: permission.value,
                "onUpdate:modelValue": ($event: any) => ((permission.value) = $event),
                disabled: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(permission.name), 1)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"]))
            }), 128))
          ])
        ])
      ]),
      _: 1
    }),
    (_ctx.popupState.updateText)
      ? (_openBlock(), _createBlock(_component_UpdateTextPopup, {
          key: 0,
          "initial-text-value": _ctx.userInfoToShow.email,
          title: _ctx.$t('member.editEmailAddress'),
          label: _ctx.$t('common.email'),
          action: _ctx.updateEmail,
          "field-name": "email",
          onOnUpdateTextDone: _cache[0] || (_cache[0] = ($event: any) => {
      _ctx.openNotification();
      _ctx.handleUpdateEmail();
      _ctx.openPopup('confirmAction');
    })
        }, null, 8, ["initial-text-value", "title", "label", "action"]))
      : _createCommentVNode("", true),
    (_ctx.popupState.updateName)
      ? (_openBlock(), _createBlock(_component_UpdateNamePopup, {
          key: 1,
          "first-name": _ctx.accountInfo.firstName,
          "last-name": _ctx.accountInfo.lastName,
          title: _ctx.$t('member.editFamilyName'),
          action: _ctx.updateUserName,
          onOnUpdateNameDone: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openNotification()))
        }, null, 8, ["first-name", "last-name", "title", "action"]))
      : _createCommentVNode("", true),
    _createVNode(_component_InfoSendVerificationEmail, {
      "is-info": "",
      title: _ctx.$t('member.editEmailAddress'),
      message: 
      _ctx.$t('adminManagement.resendInfo', {
        email: _ctx.userInfoToShow.emailPending,
      })
    
    }, null, 8, ["title", "message"])
  ], 64))
}