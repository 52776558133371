
import { computed, defineComponent, ref } from "vue";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import { AdminManagementRepository, RepositoryFactory } from "@/lib/https";
import UpdateTextPopup from "@/components/popups/UpdateTextPopup.vue";
import UpdateNamePopup from "@/components/popups/UpdateNamePopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import { useI18n } from "vue-i18n";
import ListItemEditInfo from "@/components/atomics/list-item/ListItemEditInfo.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import useAccount from "@/lib/compositional-logic/useAccount";
import TextButton from "@/components/atomics/button/TextButton.vue";
import InfoSendVerificationEmail from "@/components/popups/ConfirmPopup.vue";
import PermissionCheckBox from "@/components/atomics/PermissionCheckBox.vue";
import store from "@/store";

export default defineComponent({
  name: "MyAccount",
  components: {
    AfterLoginPage,
    ListItemEditInfo,
    PermissionCheckBox,
    UpdateTextPopup,
    UpdateNamePopup,
    BreadScrum,
    TextButton,
    InfoSendVerificationEmail,
  },
  setup() {
    const { popupState, openPopup } = usePopupControl();
    const { openNotification } = useNotificationControl();
    const { t } = useI18n();

    const databreadScrumLinks = [
      { text: t("sidebarAdmin.adminManagement"), to: "/adminManagement" },
      {
        text: t("common.myAccount"),
        to: "/adminManagement/my-account",
      },
    ];

    const getRowLabel = (name: string) => {
      if (name == "username") return t("member.titleFamilyName");
      if (name == "email") return t("common.email");
      if (name == "emailPending") return t("adminManagement.emailPending");
    };

    const showEditPopup = (name: keyof typeof userInfoToShow.value) => {
      if (name == "username") openPopup("updateName");
      else openPopup("updateText");
    };

    const {
      updateFullName,
      updateEmail,
      cancelChangingEmail,
      resendChangingEmail,
    } = RepositoryFactory.getRepository<AdminManagementRepository>(
      AdminManagementRepository
    );
    const { getProfile } =
      RepositoryFactory.getRepository<AdminManagementRepository>(
        AdminManagementRepository
      );

    const myProfile = ref();
    const getMyProfile = async () => {
      const res = await getProfile();
      if (!res) return;
      myProfile.value = res;
    };

    const handleUpdateEmail = async () => {
      await getMyProfile();
      store.commit("account/getMyProfile", {
        email: myProfile.value.email,
        emailPending: myProfile.value.emailPending,
      });
    };

    const updateUserName = async (lastName: string, firstName: string) => {
      const form = new FormData();
      form.append("lastName", lastName);
      form.append("firstName", firstName);
      await updateFullName(form);
      await getMyProfile();
      store.commit("account/getMyProfile", {
        lastName: myProfile.value.lastName,
        firstName: myProfile.value.firstName,
      });
    };

    const { permissions, firstName, lastName } = useAccount();

    const accountInfo = computed(() => store.state.account);
    const userInfoToShow = computed(() => ({
      username: accountInfo.value.lastName + " " + accountInfo.value.firstName,
      email: accountInfo.value.email,
      emailPending: accountInfo.value.emailPending,
    }));
    const lstPermission = computed(() => [
      {
        name: t("adminManagement.templateManagement"),
        value: permissions.templateManagement,
      },
      {
        name:
          t("adminManagement.lstCompany") +
          "/" +
          t("adminManagement.customerList"),
        value: permissions.companyAndMemberManagement,
      },
      {
        name: t("adminManagement.emailManagement"),
        value: permissions.emailManagement,
      },
      {
        name: t("adminManagement.accountManagement"),
        value: permissions.adminManagement,
      },
      {
        name: t("adminManagement.cMindManagement"),
        value:
          permissions.partnerCompanyId ==
            "00000000-0044-6967-6974-616c5369676e" ||
          permissions.partnerCompanyId == "00000000-0000-0000-0000-432d6d696e64"
            ? permissions.partnerManagement
            : 0,
      },
      {
        name: t("adminManagement.wizPaymentManagement"),
        value:
          permissions.partnerCompanyId ==
            "00000000-0044-6967-6974-616c5369676e" ||
          permissions.partnerCompanyId == "00000000-0000-0000-0000-00000057697a"
            ? permissions.partnerManagement
            : 0,
      },
    ]);

    const handleCancel = async () => {
      await cancelChangingEmail();
      await getMyProfile();
      store.commit("account/getMyProfile", {
        email: myProfile.value.email,
        emailPending: myProfile.value.emailPending,
      });
    };

    const handleResend = async () => {
      await resendChangingEmail();
      openNotification({ text: "確認メールが再送されました" });
    };

    return {
      firstName,
      lastName,
      userInfoToShow,
      popupState,
      showEditPopup,
      getRowLabel,
      lstPermission,
      updateEmail,
      updateUserName,
      openNotification,
      databreadScrumLinks,
      handleCancel,
      resendChangingEmail,
      handleResend,
      openPopup,
      handleUpdateEmail,
      getMyProfile,
      accountInfo,
    };
  },
});
